<template>
  <div class="school-packet-book mt-3 mx-3">
    <v-row
      v-if="selectedschool === 'add'"
      class="school-packet-header d-flex justify-space-between px-0 py-4"
    >
      <v-col md="4">
        <h3>
          Kamu memilih paket buku
          <br>
          “{{ selectedpacket.name }}”
        </h3>
      </v-col>
      <v-col md="4">
        <v-row>
          <v-col md="5">
            <div>
              <p class="font-weight-bold">
                Nama Paket
              </p>
              <p class="font-weight-bold">
                Deskripsi
              </p>
              <p class="font-weight-bold">
                Jenjang
              </p>
            </div>
          </v-col>
          <v-col md="6">
            <div class="">
              <p class="font-weight-light text-truncate">
                : {{ selectedpacket.name }}
              </p>
              <p class="font-weight-light text-truncate">
                : {{ selectedpacket.description }}
              </p>
              <p class="font-weight-light">
                : {{ selectedpacket.level.name }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="4">
        <v-row>
          <v-col md="6">
            <div>
              <p class="font-weight-bold">
                Harga
              </p>
              <p class="font-weight-bold">
                Jumlah Buku
              </p>
            </div>
          </v-col>
          <v-col md="6">
            <div>
              <p class="font-weight-light">
                : Rp. {{ selectedpacket.price }}
              </p>
              <p class="font-weight-light">
                : {{ selectedpacket.total_library }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div
      v-else-if="selectedschool === 'edit'"
      class="mb-6"
    >
      <h2>Semua Paket</h2>
      <p>Daftar paket buku yang sudah dibeli</p>
      <v-row>
        <v-col
          v-for="packet in packets"
          :key="packet.name"
          md="3"
          cols="12"
        >
          <v-card
            outlined
            :class="cardActive == packet.uuid ? 'card-active' : false"
            clickable
            @click.prevent="handlerPacketOption(packet.uuid)"
          >
            <v-card-text>
              <p class="font-weight-bold mb-2">
                {{ packet.name }}
              </p>
              <small>Tanggal Beli : {{ dateFormat(packet.created_at) }}</small>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <div
        v-if="packets.length > 0"
        class="my-4"
      >
        <h2 class="mb-4">
          Informasi Paket
        </h2>
        <v-row>
          <v-col
            md="5"
            cols="12"
          >
            <div class="d-inline-block">
              <div class="grey lighten-1">
                Nama Paket
              </div>
              <div class="grey lighten-1">
                Deskripsi
              </div>
              <div class="grey lighten-1">
                Jenjang
              </div>
            </div>
            <div class="d-inline-block ml-4">
              <div class="black--text">
                : {{ packetDetail.name }}
              </div>
              <div class="black--text">
                : {{ packetDetail.description }}
              </div>
              <div class="black--text">
                : {{ packetDetail.level }}
              </div>
            </div>
          </v-col>
          <v-col
            md="5"
            cols="12"
          >
            <div class="d-inline-block">
              <div>Harga</div>
              <div>Jumlah Buku</div>
              <div>Tanggal Beli</div>
            </div>
            <div class="d-inline-block ml-4">
              <div
                class="black--text"
              >
                : <span v-if="packetDetail.price">Rp. {{ packetDetail.price }}</span>
              </div>
              <div
                class="black--text"
              >
                : <span v-if="packetDetail.total_book">{{ packetDetail.total_book }}</span>
              </div>
              <div
                class="black--text"
              >
                : <span v-if="packetDetail.created_at">{{ dateFormat(packetDetail.created_at) }}</span>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
      <div v-if="packetDetail.uuid && packets.length > 0">
        <v-btn
          color="primary"
          small
          class="mr-4"
          @click="confirmDestroy(packetDetail.uuid)"
        >
          Hapus Paket
        </v-btn>
        <v-btn
          color="primary"
          outlined
          small
          @click="destroyOption(packetDetail.uuid)"
        >
          Hapus Pilihan
        </v-btn>
      </div>
    </div>

    <v-divider></v-divider>
    <div class="school-packet-title d-flex align-center justify-space-between px-0 py-4">
      <v-col
        cols="5"
        class="px-0"
      >
        <h2>Daftar Buku</h2>
        <p class="text-body-2 mt-1">
          Daftar buku telah ditentukan oleh sistem tidak dapat ubah
        </p>
      </v-col>
    </div>
    <v-row class="">
      <v-col
        md="5"
        cols="12"
      >
        <v-card outlined>
          <v-card-title>
            Kategori
          </v-card-title>
          <v-divider></v-divider>

          <v-progress-circular
            v-if="isLoadingList"
            indeterminate
            color="primary"
            class="position-absolute"
            style="top:60%;left:50%;transform:translate(-50%,-50%);z-index:1"
          ></v-progress-circular>
          <v-list
            height="400"
            class="overflow-auto"
            rounded
          >
            <v-list-item @click="listLibrary">
              <v-list-item-content>
                <v-list-item-title>
                  Semua Kategori
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item-title>
              <v-treeview
                activatable
                rounded
                hoverable
                color="primary"
                :items="categories"
                item-key="uuid"
                item-text="name"
                item-children="sub_categories"
              >
                <template
                  slot="label"
                  slot-scope="{ item }"
                >
                  <a
                    class="d-flex justify-space-between align-center"
                    @click.prevent="changeLibraryList(item.uuid)"
                  >
                    <span
                      class="d-inline-block text-truncate"
                      style="max-width:150px"
                    >{{ item.name }}</span>
                    <v-chip
                      color="primary"
                    >
                      {{ item.total_libraries }} Buku
                    </v-chip>
                  </a>
                </template>
              </v-treeview>
            </v-list-item-title>
          </v-list>
        </v-card>
      </v-col>
      <v-col
        md="7"
        cols="12"
      >
        <v-card outlined>
          <v-card-title>Daftar Buku</v-card-title>
          <v-simple-table
            height="400"
            class="overflow-auto"
          >
            <template v-slot:default>
              <thead style="border-top: none">
                <tr>
                  <th class="text-left">
                    #
                  </th>
                  <th class="text-left">
                    Nama Buku
                  </th>
                </tr>
              </thead>
              <v-progress-circular
                v-if="isLoadingData"
                indeterminate
                color="primary"
                class="position-absolute"
                style="top:60%;left:50%;transform:translate(-50%,-50%);z-index:1"
              ></v-progress-circular>
              <tbody v-else>
                <tr
                  v-for="(library,index) in libraries"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ library.name }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
    <div class="d-flex justify-center mt-4 mb-1">
      <v-btn
        v-if="selectedschool === 'add'"

        :disabled="isLoadingButton"
        :loading="isLoadingButton"
        color="primary"
        class="mx-2"
        large
        @click="saveData()"
      >
        Simpan
      </v-btn>
      <v-btn
        v-if="selectedschool === 'edit'"
        :disabled="isLoadingButton"
        :loading="isLoadingButton"
        color="primary"
        class="mx-2"
        large
        @click="updateData()"
      >
        Update
      </v-btn>
      <v-btn
        color="primary"
        outlined
        class="mx-2"
        large
        @click="prevTab()"
      >
        Sebelumnya
      </v-btn>
    </div>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Paket"
      @close-button="confirmDialog = false"
      @destroy-button="destroyPacket"
    >
      <template v-slot:body>
        Apakah anda yakin ingin menghapus paket "{{ packetDetail.name }}" ?
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import dateTimeFormat from '@/utils/date/dateTimeFormat'
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'

export default {
  name: 'SchoolPacketBook',
  components: {
    ConfirmDialog,
  },
  props: {
    selectedschool: {
      type: String,
      default: () => '',
    },
    selectedpacket: {
      type: Object,
      default: () => {},
    },
    dataschool: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      school: {
        data_school: '',
        npsn: '',
        username: '',
        password: '',
        email: '',
        name: '',
        jenjang: '',
        tahun_ajaran: '',
        semester: '',
        province_uuid: '',
        regencies_uuid: '',
        district_uuid: '',
        address: '',
        package_uuid: '',
        image: null,
        user_uuid: '',
      },
      isLoadingData: false,
      isLoadingList: false,
      dialog: '',
      confirmDialog: false,
      searchDebounce: '',
      categories: [],
      libraries: [],
      notices: [
        { title: 'Buku yang akan dibeli', isChecked: true },
        { title: 'Buku yang sudah dibeli', isChecked: true },
        { title: 'Buku yang tidak dibeli', isChecked: false },
      ],
      packet: [],
      packets: [],
      packet_uuid: '',
      packetDetail: {},
      isLoadingButton: false,
      isDisabledButton: false,
      timeout: null,
      totalBook: 0,
      librariesToAdd: [],
      librariesToRemove: [],
      librariesSelected: [],
      cardActive: false,
      isEmpty: false,
    }
  },
  computed: {
    search: {
      get() {
        return this.searchDebounce
      },
      set(val) {
        if (val.length > 3 || val.length === 0) {
          if (this.timeout) clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.searchDebounce = val
            this.listLibrary({ library: this.searchDebounce })
          }, 300)
        }
      },
    },
  },
  watch: {
    dataschool() {
      if (this.dataschool.name !== '') this.assignValue()
    },
    async selectedpacket() {
      await this.listLibrary()
    },
  },
  async mounted() {
    if (this.dataschool.name !== '') await this.assignValue()
    this.listCategory()
    this.listLibrary()
    this.listPacket()
  },
  methods: {
    assignValue() {
      this.school = { ...this.dataschool }
      this.listPacket()
    },
    resetForm() {
      this.school.uuid = ''
      this.school.npsn = ''
      this.school.username = ''
      this.school.password = ''
      this.school.email = ''
      this.school.name = ''
      this.school.jenjang = ''
      this.school.tahun_ajaran = ''
      this.school.semester = ''
      this.school.province_uuid = ''
      this.school.regencies_uuid = ''
      this.school.district_uuid = ''
      this.school.address = ''
      this.school.package_uuid = ''
      this.school.image = null
      this.school.user_uuid = ''
      this.school.data_school = ''
    },

    async saveData() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('npsn', this.school.npsn)
      this.formData.append('address', this.school.place.address)
      this.formData.append('jenjang', this.school.jenjang)
      this.formData.append('name', this.school.name)
      this.formData.append('package_uuid', this.school.packet)
      this.formData.append('password', this.school.password)
      this.formData.append('data_school', this.school.data_school)
      this.formData.append('province_uuid', this.school.place.province)
      if (this.formData.get('province_uuid') === 'null' || typeof this.school.place.province === 'object') {
        this.formData.delete('province_uuid')
      }
      this.formData.append('regencies_uuid', this.school.place.regencies.uuid)
      if (this.school.place?.regencies?.uuid === undefined) this.formData.delete('regencies_uuid')
      this.formData.append('district_uuid', this.school.place.district.uuid)
      if (this.school.place?.district?.uuid === undefined) this.formData.delete('district_uuid')
      this.formData.append('semester', this.school.semester)
      this.formData.append('tahun_ajaran', this.school.tahun_ajaran)
      this.formData.append('username', this.school.username)
      this.formData.append('logo', this.school.image)
      if (this.school.image === null) {
        this.formData.delete('logo')
      }

      await this.$services.ApiServices.add('school', this.formData).then(
        ({ data }) => {
          this.school = data.data
          this.showSnackbar({
            text: 'Add School successfully',
            color: 'success',
          })
          this.isLoadingButton = false
        },
        err => {
          console.error(err)
          this.isLoadingButton = false

          return false
        },
      )
      if (JSON.parse(localStorage.getItem('feature')).length < 1 || localStorage.getItem('feature') === null) {
        this.$emit('close-dialog')
        this.$emit('fetch-school')

        return true
      }
      if (this.school.user_uuid !== undefined) await this.addAbility(this.school.user_uuid)

      return true
    },
    async updateData() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('npsn', this.school.npsn)
      this.formData.append('address', this.school.place.address)
      this.formData.append('jenjang', this.school.jenjang)
      this.formData.append('name', this.school.name)
      this.formData.append('data_school', this.school.data_school)
      if (this.school.password !== undefined) this.formData.append('password', this.school.password)
      this.formData.append('province_uuid', this.school.place?.province?.uuid || this.school.place?.province)
      if (this.formData.get('province_uuid') === 'null') this.formData.delete('province_uuid')
      this.formData.append('regencies_uuid', this.school.place?.regencies?.uuid)
      if (this.school.place?.regencies?.uuid === undefined) this.formData.delete('regencies_uuid')
      this.formData.append('district_uuid', this.school.place?.district?.uuid)
      if (this.school.place?.district?.uuid === undefined) this.formData.delete('district_uuid')
      this.formData.append('semester', this.school.semester)
      this.formData.append('tahun_ajaran', this.school.tahun_ajaran)
      this.formData.append('logo', this.school.image)
      if (typeof this.school.image === 'string' || this.school.image === null) {
        this.formData.delete('logo')
      }

      await this.$services.ApiServices.update('school', this.formData, this.school.uuid).then(
        ({ data }) => {
          this.school = data.data
          this.showSnackbar({
            text: 'Update School successfully',
            color: 'success',
          })
          this.isLoadingButton = false
          this.$emit('close-dialog')
          this.$emit('fetch-school')
        },
        err => {
          console.error(err)
          this.isLoadingButton = false

          return false
        },
      )
      await this.addAbility(this.school.user_uuid)

      return true
    },
    async addAbility(uuid) {
      if (!JSON.parse(localStorage.getItem('feature'))) {
        return true
      }
      this.formData = new FormData()
      const ability = JSON.parse(localStorage.getItem('feature'))
      ability.forEach(element => {
        if (element !== 'DashboardSchool') {
          this.formData.append('subject[]', element)
        }
      })
      this.formData.append('users_uuid', uuid)
      await this.$services.ApiServices.add('ability', this.formData).then(
        ({ data }) => {
          this.showSnackbar({
            text: 'Add ability to school successfully',
            color: 'success',
          })
          this.$emit('close-dialog')
          localStorage.removeItem('feature')
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
        },
      )
      this.$emit('fetch-school')
      this.resetForm()

      return true
    },
    async listPacket(params = {}) {
      await this.$services.ApiServices.list('packageschool', { ...params, school_uuid: this.school.uuid }).then(
        ({ data }) => {
          this.packets = data.data
          this.isEmpty = true
          if (this.packets.length < 1) {
            this.empty = false
          }
        },
        err => console.error(err),
      )
    },
    async listLibrary() {
      this.isLoadingData = true
      if (this.selectedschool === 'edit' && this.school.uuid !== undefined) {
        await this.$services.ApiServices.getParams('library', this.school.uuid, { per_page: 'all' }).then(
          ({ data }) => {
            this.libraries = data.data
          },
          err => console.error(err),
        )
      }
      if (this.selectedschool === 'add') {
        await this.$services.ApiServices.get('packet', this.selectedpacket.uuid).then(
          ({ data }) => {
            this.libraries = data.data.library
          },
          err => console.error(err),
        )
      }
      this.isLoadingData = false
    },
    async listCategory() {
      this.isLoadingList = true
      await this.$services.ApiServices.list('category', { per_page: 'all', parent: 1 }).then(
        ({ data }) => {
          this.categories = data.data
        },
        err => console.error(err),
      )
      this.isLoadingList = false
    },
    async handlerPacketOption(uuid) {
      await this.$services.ApiServices.get('packageschool', uuid).then(
        ({ data }) => {
          this.packetDetail = data.data
          this.packet_uuid = this.packetDetail.uuid
          this.cardActive = uuid
        },
        err => console.error(err),
      )
    },
    async getSelectedLibraries() {
      await this.$services.ApiServices.get('packet', this.selectedpacket.uuid).then(
        ({ data }) => {
          if (data.data.library) {
            this.librariesSelected = data.data.library
            this.packet = { ...this.packet, total_library: data.data.total_library }
          } else this.librariesSelected = []
        },
        err => console.error(err),
      )
    },
    async changeLibraryList(uuid) {
      this.isLoadingData = true
      if (this.selectedschool === 'add') await this.getSelectedLibraries()

      await this.$services.ApiServices.getParams('librarycategory', uuid, { per_page: 'all' }).then(
        ({ data }) => {
          const librariesData = data.data
          librariesData.forEach(item => {
            item.selected = this.librariesToAdd.includes(item.uuid)
            this.librariesSelected.forEach(selected => {
              if (item.uuid === selected.uuid) item.selected = true
            })
          })
          this.libraries = librariesData
          this.libraries.sort((a, b) => Number(b.selected) - Number(a.selected))
        },
        err => console.error(err),
      )
      this.isLoadingData = false
    },
    async confirmDestroy(uuid) {
      this.confirmDialog = true
      this.packet_uuid = uuid
      this.isDisabledButton = false
    },
    async destroyPacket() {
      this.isLoadingButton = true
      const packet = { package_school_uuid: this.packet_uuid, school_uuid: this.school.uuid }
      await this.$services.ApiServices.delete('libraryschool', packet).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
          this.listLibrary()
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
        },
      )
      await this.listPacket()
      this.packetDetail = {
        level: '',
      }
      this.isLoadingButton = false
      this.isDisabledButton = true
      this.confirmDialog = false
    },
    destroyOption(uuid) {
      if (uuid !== null) {
        this.cardActive = false
        this.packetDetail = {}
      }
    },
    prevTab() {
      this.$emit('prev-tab')
      this.packetDetail = {}
      this.cardActive = false
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },
    dateFormat(time) {
      return dateTimeFormat(time)
    },
  },
}
</script>

<style>
.school-packet-header .header-left ul {
  margin-left: 0;
  /* border: 1px solid #000; */
}
.school-packet-header ul li {
  list-style: none;
  margin-bottom: 1rem;
}
.card-active {
  outline: 1px solid #ec008c !important;
  background: rgba(236, 0, 140, 0.05) !important;
}

.v-application a {
  color: #746d7d;
  text-decoration: none;
}
</style>
